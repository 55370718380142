import _ from 'lodash';
import { AnyAction } from 'redux';

import { notificationsSettingState } from './initialStates';
import * as types from '../actions/actionTypes';
import { NotificationsSettingState } from '../common/types/reduxStateTypes';

const notificationsSetting = (state: NotificationsSettingState = notificationsSettingState, action: AnyAction) => {
  switch (action.type) {
    case types.NOTIFICATIONS_SETTING_FETCH_SUCCESS:
      const { data: { data: content, id } } = action;
      let newState: NotificationsSettingState = notificationsSettingState;

      if (content?.trim().length > 0) {
        newState = JSON.parse(content) as NotificationsSettingState;
      }

      const newWorkflow = { ...state.workflow, ...newState.workflow };

      return {
        ...state,
        ...newState,
        workflow: newWorkflow,
        id: id
      };
    default:
      return state;
  }
};

export default notificationsSetting;
