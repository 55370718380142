import {
  AgendaState,
  AppState,
  ArchiveState,
  MyAccountState,
  NotificationState,
  NotificationsSettingState,
  PartnerInvitationState,
  UserTableFiltersState,
  TableState,
  UserAppSettingsState,
  UserFiltersState,
  UserPermissionsState,
  WorkflowState,
  RootState,
  AlertState,
  RouterState
} from '../common/types/reduxStateTypes';
import { loadState } from '../components/common/LocalStorage';
import { initialState as appTokenInitialState } from './appTokenReducer';
import { initialState as ajaxStatusInitialState } from './ajaxStatusReducer';
import { initialState as userInitialState } from './userReducer';
import { initialState as sideBarInitialState } from './sideBarReducer';
import { initialState as accountMenuInitialState } from './accountMenuReducer';
import { initialState as paymentInitialState } from './paymentReducer';
import { initialState as companyVerificationInitialState } from './companyVerificationReducer';
import { initialState as dashboardInitialState } from './dashboardReducer';
import { initialState as companyInitialState } from './companyReducer';
import { initialState as virtualInitialState } from './virtualReducer';
import { initialState as billingInitialState } from './billingReducer';
import { initialState as attachmentsInitialState } from './attachmentsReducer';


// Load state from local storage
const persistedState = loadState();
let { workflow, browser, app, notification } = persistedState || {};

export const tableInitialState: TableState = {
  data: [],
  sort: [{ field: "id", dir: "asc" }],
  defaultSort: [],
  filter: [],
  total: 0,
  skip: 0,
  take: 25,
  cache: {
    skip: 0,
    take: 100
  },
  gridFilter: {},
  current: 0
};

export const partnerInvitationInitialState: PartnerInvitationState = {
  partnerCompanyIdentificationNumber: '',
  partnerCountryCode: 'CZE',
  partnerName: '',
  partnerEmail: '',
  message: '',
  canInvitation: false,
  emailIsMandatory: false,
  partnerInvitationAccepted: undefined,
  partnerInvitationRejected: undefined
};

export const myAccountInitialState: MyAccountState = {
  id: 0,
  firstname: '',
  lastname: '',
  phone: '',
  street: '',
  city: '',
  postalCode: '',
  country: '',
  isPasswordSecured: true,
  invalidPassword: false,
  email: '',
  langCode: '',
};

export const userPermissionsInitialState: UserPermissionsState = {
  permissions: [],
  roles: []
};

export const workflowInitialState: WorkflowState = {
  variableTypes: [],
  variables: [],
  stepActions: [],
  scenarios: [],
  activeScenarios: [],
  agreementProcessScenarios: [],
  agreementProcessStepActions: [],
  emailRecord: undefined,
  historyRecords: [],
  queueFilter: false,
  related: [],
  chart: {
    categories: [],
    series: []
  },
  graph: null,
  graphState: 'notLoaded',
  unsolicitedContractors: [],
  ...workflow
};

export const agendaInitialState: AgendaState = {
  users: [],
  partners: [],
  devices: [],
  mail: {},
  cloudDrive: {},
  cloudDrives: [],
  pairingKey: undefined,
  isWorkflowEnabled: false,
  isWorkflowEnabledForIncoming: false,
  isWorkflowEnabledForOutgoing: false,
  isArchiveEnabled: false,
  isSignWithCertificate: false,
  isStampMainDocument: false,
  isStampAttachments: false,
  isArchivingInScenariosEnabled: true,
  isDatariosObjectsArchivingEnabled: true,
  useExtendedNote: false,
  erpType: 0,
};

export const appInitialState: AppState = {
  lang: 'cs',
  warningDialog: {
    open: false,
    tKey: '',
    tKeys: [],
  },
  browser: {
    resizablePanels: {
      workflowQueue: {
      }
    },
    ...browser
  },
  needSignoutDialog: {
    open: false
  },
  resolution: undefined,
  currentPage: {
    title: '',
    params: {
      translateTitle: true,
      bindings: [],
    },
    confirmQuestion: 'ConfirmQuestions.NotSavedChanges',
  },
  viewMode: 'ONE_COLUMN',
  hasUnsavedChanges: false,
  ...app
};

export const notificationInitialState: NotificationState = {
  counts: {},
  log: {},
  read: [],
  ...notification
};

export const userAppSettingsInitialState: UserAppSettingsState = {
  id: 0,
  data: {
    userViewModes: {
      workflowInputQueue: 'TWO_COLUMNS',
      workflowAgreementProcess: 'TWO_COLUMNS',
      archive: 'TWO_COLUMNS',
    },
    columnNames: {
      archive: [
        'name',
        'dateOfCreate',
        'senderEmail',
        'state'
      ]
    },
    lastSeenNotifyDates: {},
    workflowFilter: {
      filterId: 0,
      isActive: false,
    },
  },
};


export const archiveInitialState: ArchiveState = {
  related: {},
  archiveFile: null,
};

export const userFiltersInitialState: UserFiltersState = {
  workflow: [],
};

export const userTableFiltersInitialState: UserTableFiltersState = { id: 0, data: {} };

export const notificationsSettingState: NotificationsSettingState = {
  workflow: {
    isEnabled: false,
    scenarioIsNotSet: {
      isEnabled: false,
      emails: '',
      interval: 0,
      userIds: []
    },
    notApproved: {
      isEnabled: false,
      emails: '',
      interval: 0,
      userIds: []
    },
    unsolicitedContractor: {
      isEnabled: false,
      emails: '',
      interval: 0,
      userIds: []
    }
  }
};

export const alertInitialState: AlertState[] = [];

export const routerState: RouterState = {
  location: {
    pathname: "/",
    search: "",
    hash: ""
  },
};

export const initialState: RootState = {
  app: appInitialState,
  appToken: appTokenInitialState,
  agenda: agendaInitialState,
  archive: archiveInitialState,
  ajaxStatus: ajaxStatusInitialState,
  myAccount: myAccountInitialState,
  notification: notificationInitialState,
  notificationsSetting: notificationsSettingState,
  partnerInvitation: partnerInvitationInitialState,
  userTableFilters: userTableFiltersInitialState,
  table: tableInitialState,
  user: userInitialState,
  userAppSettings: userAppSettingsInitialState,
  userFilters: userFiltersInitialState,
  userPermissions: userPermissionsInitialState,
  workflow: workflowInitialState,
  sideBar: sideBarInitialState,
  alerts: alertInitialState,
  accountMenu: accountMenuInitialState,
  payment: paymentInitialState,
  companyVerification: companyVerificationInitialState,
  dashboard: dashboardInitialState,
  company: companyInitialState,
  virtual: virtualInitialState,
  billing: billingInitialState,
  router: routerState,
  attachments: attachmentsInitialState,
};
