export const NAVIGATION_PATHS = {
  ROOT: '/',
  PARTNER_INVITATIONS: '/partner-invitations',
  SIGN_IN: '/signin',
  SIGN_UP_COMPANY: '/payment?token=',
  USER_INVITATIONS: '/user-invitations',
  USER_INVITATION_CREATE: '/user-invitations/create',
  USER_INVITATION_ID: '/user-invitation/:id',
  COMPANY_INVITATION: '/company-invitation/:id/:status',
  COMPANY_VERIFICATION: '/company-verification',
  COMPANY_VERIFICATION_TOKEN: '/company-verification?token=',
  PARTNER_INVITATION_CREATE: '/partner-invitations/create',
  INVOICES_OUTGOING: '/invoices-outgoing',
  INVOICES_INCOMING: '/invoices-incoming',
  INVOICES: '/invoices',
  USERS: '/users',
  PARTNERS: '/partners',
  DEVICES: '/devices',
  EVENTS: '/events',
  FORGOTTEN_PASSWORD: '/forgotten-password',
  RESET_PASSWORD: '/reset-password',
  SIGN_UP: '/signup',
  PAYMENT: '/payment',
  PAYMENT_STATUS: '/payment/:status',
  EMAIL_VERIFICATION: '/email-verification',
  AGENDA_SELECTION: '/agenda-selection',
  ACCEPT_PARTNER: '/accept-partner',
  REJECT_PARTNER: '/reject-partner',
  USER_PERMISSIONS: '/user-permissions',
  SIGN_UP_CODE: '/signup?code=',
  WORKFLOW_QUEUE: '/workflow-queue',
  WORKFLOW_AGREEMENT_PROCESS: '/workflow-agreement-process',
  WORKFLOW_QUEUE_RECORD_MAIL_TO: '/workflow-record/:id/mail',
  WORKFLOW_OUTPUT_QUEUE: '/workflow-output-queue',
  WORKFLOW_HISTORY: '/workflow-history',
  WORKFLOW_STATISTICS: '/workflow-statistics',
  ARCHIVE: '/archive',
  ARCHIVE_PACKAGES: '/archive-packages',
  ARCHIVE_PACKAGE_CREATE: '/archive-packages/create',
  ARCHIVE_PACKAGE_EDIT: '/archive-packages/:id',
  ARCHIVE_PACKAGE_DOWNLOAD: '/archive-packages/download',
  SETTINGS_COMPANY: '/settings/company',
  SETTINGS_ERP: '/settings/erp',
  SETTINGS_MY_ACCOUNT: '/settings/my-account',
  SETTINGS_MY_ACCOUNT_TOKEN: '/settings/my-account/token',
  SETTINGS_WORKFLOW: '/settings/workflow',
  SETTINGS_WORKFLOW_FILTERS: '/settings/workflow/filters',
  SETTINGS_WORKFLOW_FILTER_CREATE: '/settings/workflow/filters/create',
  SETTINGS_WORKFLOW_FILTER_EDIT: '/settings/workflow/filters/:id',
  SETTINGS_WORKFLOW_GENERAL: '/settings/workflow/general',
  SETTINGS_COMPANY_MAIL: '/settings/workflow/general/mail',
  SETTINGS_CLOUD_DRIVE: '/settings/workflow/general/cloudDrive',
  SETTINGS_WORKFLOW_AGREEMENT: '/settings/workflow/agreement',
  SETTINGS_WORKFLOW_PREDICTIONS_AND_RULES: '/settings/workflow/predictions-and-rules',
  SETTINGS_WORKFLOW_SCENARIOS: '/settings/workflow/scenarios',
  SETTINGS_WORKFLOW_SCENARIO: '/settings/workflow/scenarios/scenario',
  SETTINGS_WORKFLOW_VARIABLETYPES: '/settings/workflow/variable-types',
  SETTINGS_WORKFLOW_AGREEMENT_PROCESS_SCENARIO: '/settings/workflow/agreement/process-scenario',
  SETTINGS: '/settings',
  SETTINGS_BILLING: '/settings/billing',
  SETTINGS_BILLING_CREDIT: '/settings/billing/credit',
  SETTINGS_BILLING_CREDIT_BUY: '/settings/billing/credit/buy',
  SETTINGS_BILLING_CREDIT_PAYMENT: '/settings/billing/credit/payment',
  SETTINGS_BILLING_TRANSACTIONS: '/settings/billing/transactions',
  SETTINGS_BILLING_HISTORY: '/settings/billing/history',
  SETTINGS_PRICELIST: '/settings/pricelist',
  SETTINGS_PRICELIST_CONTACT_US: '/settings/pricelist/contact-us',
  SETTINGS_ARCHIVE: '/settings/archive',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  SETTINGS_UNSOLICITED_CONTRACTORS: '/settings/workflow/unsolicited-contractors',
  SETTINGS_UNSOLICITED_CONTRACTOR: '/settings/workflow/unsolicited-contractors/:id',
  SETTINGS_UNSOLICITED_CONTRACTOR_CREATE: '/settings/workflow/unsolicited-contractors/create',
  SUPPORT: '/contact-support',
  GDPR: 'https://www.datarios.cz/zpracovani-osobnich-udaju/',
  COOKIES_INFO: 'https://www.datarios.cz/informace-o-cookies',
  TERMS_AND_CONDITIONS: 'https://www.datarios.cz/vseobecne-obchodni-podminky-systemu-datarios.pdf',
  DATARIOS: 'https://www.datarios.cz',
  DATABOX: 'https://www.mojedatovaschranka.cz',
  DATARIOS_DESKTOP: 'http://desktop.datarios.cz',
  DATARIOS_DESKTOP_APP: 'http://desktop.datarios.cz/public/app/Datarios.exe',
};

export const API_PATHS = {
  AGENDA: 'api/agenda',
  AGENDA_SET_ERP: 'api/agenda/setErp',
  AGENDA_MAIL: 'api/agendaMail',
  AGENDA_CLOUD_DRIVE: 'api/agendaCloudDrive',
  AGENDA_CLOUD_DRIVE_GOOGLE_CONSENT: `api/agendaCloudDrive/googleConsent`,
  AGENDA_CLOUD_DRIVE_GOOGLE_APP_ID: `api/agendaCloudDrive/googleAppId`,
  AGENDA_CLOUD_DRIVES_ALL: `api/agendaCloudDrives/all`,
  AGENDA_CLOUD_DRIVES_ALL_WITH_DELETED: `api/agendaCloudDrives/allWithDeleted`,
  ARCHIVE_DOCUMENT: 'api/archiveDocument/[:id]',
  ARCHIVE_DOCUMENT_HISTORY: 'api/archiveDocumentHistory/[:id]',
  ARCHIVE_DOCUMENT_RELATED: 'api/archiveDocument/related/[:id]',
  ARCHIVE_DOCUMENT_SET_AS_INVALID: 'api/archiveDocument/setAsInvalid/[:id]',
  ARCHIVE_DOCUMENT_SET_AS_VALID: 'api/archiveDocument/setAsValid/[:id]',
  ARCHIVE_FILE: 'api/archiveFiles/[:id]',
  ARCHIVE_PACKAGE: 'api/archivePackage',
  ARCHIVE_PACKAGE_TOKEN_INFO: 'api/archivePackageTokenInfo',
  ARCHIVE_PACKAGE_DOWNLOAD: 'api/archive-packages/download?token=',
  ARCHIVE_PACKAGE_RECIPIENT_SEND_MAIL: 'api/archivePackageRecipient/[:id]/sendMail',
  ARCHIVE_PACKAGE_EXTEND: 'api/archivePackage/[:id]/extend',
  ARCHIVE_PACKAGE_EXPIRE: 'api/archivePackage/[:id]/expire',
  ARCHIVE_PACKAGE_CANCEL: 'api/archivePackage/[:id]/cancel',
  ARCHIVE_PACKAGE_REACTIVATE: 'api/archivePackage/[:id]/reactivate',
  ARCHIVE_PACKAGE_RECIPIENT_ADD_FOR_PACKAGE: 'api/archivePackageRecipient',
  ARCHIVE_PACKAGE_RECIPIENT_SEND_PIN: 'api/archivePackageRecipient/[:id]/sendPIN',
  ARCHIVE_PACKAGE_RECIPIENT_GET_PACKAGE_LINK: 'api/archivePackageRecipient/[:id]/archivePackageLink',
  CREATE_REGISTRATION_PAYMENT: `api/payment/create/registration`,
  PAYMENT_REGISTRATION_INFO: `api/payment/registration/info`,
  PAYMENT_AUTHORIZED_INFO: 'api/payment/authorized/info',
  PARTNER_INVITATION_INFO: 'api/partnerInvitationInfo',
  COMPANY: `api/company`,
  COMPANY_INVITATION_SET_STATUS: 'api/companyInvitation/setStatus',
  COMPANY_INVITATION_ACCEPT: 'api/companyInvitation/[:id]/accept',
  COMPANY_INVITATION_REJECT: 'api/companyInvitation/[:id]/reject',
  COMPANY_VERIFICATION: 'api/companyVerification',
  COMPANY_VERIFICATION_SEND: 'api/companyVerification/send',
  COMPANY_VERIFICATION_VERIFY: 'api/companyVerification/verify',
  COMPANY_VERIFICATION_RESTART_PAYMENT: 'api/companyVerification/restartPayment',
  ERP_CONFIGURATION: 'api/erp',
  ATTACHMENT: 'api/attachment',
  ATTACHMENTS: 'api/attachments',
  ATTACHMENT_DOWNLOAD: 'api/attachment/download',
  AUTH_NEW_PASSWORD: 'api/auth/newpassword',
  AUTH_FORGOTTEN_PASSWORD: 'api/auth/forgottenpassword',
  AUTH_VERIFY_EMAIL: 'api/auth/verifyemail',
  AUTH_SIGN_UP_USER: 'api/auth/signupuser',
  AUTH_SIGN_UP_COMPANY: 'api/auth/signupcompany',
  AUTH_SIGN_UP_COMPLETE_USER: 'api/auth/signupcompleteuser',
  AUTH_SIGN_UP_VIA_INVITATION: 'api/auth/signUpViaInvitation',
  AUTH_SIGN_IN: 'api/auth/token',
  AUTH_RENEW_TOKEN: 'api/auth/renew',
  AUTH_GET_COMPANY_INFO: 'api/auth/getCompanyInfo',
  AUTH_CLEAR: 'api/auth/clear',
  AUTH_GET_USER_AGENDAS: 'api/auth/getUserAgendas',
  AUTH_SET_USER_AGENDA: 'api/auth/setAgenda',
  AUTH_CREATE_SIGN_UP_COMPANY_TOKEN: `api/auth/createSignUpCompanyToken`,
  AUTH_MS_CONSENT: `api/auth/MsConsent`,
  PERMISSIONS_GET_ALL: 'api/permissions/all',
  USERS_GET_ALL: 'api/users/all',
  ROLES_GET_ALL: 'api/roles/all',
  PARTNERS_GET_ALL: 'api/partners/all',
  UNSOLICITED_CONTRACTOR: 'api/unsolicitedContractor',
  UNSOLICITED_CONTRACTOR_INFO: 'api/unsolicitedContractor/info',
  USER_PERMISSIONS_BULK: 'api/userPermissions/byType/bulk',
  USER_INVITATION: `api/userInvitation`,
  USER_INVITATION_EXPIRE: 'api/userInvitation/expire',
  WORKFLOW_VARIABLE_TYPE: 'api/workflowVariableType',
  WORKFLOW_VARIABLE_TYPES_GET_ALL: 'api/workflowVariableTypes/all',
  WORKFLOW_VIEW_VARIABLES: 'api/workflowVariables/view',
  WORKFLOW_VIEW_STOCK_ITEM_VARIABLES: 'api/workflowVariables/viewStockItems',
  WORKFLOW_VARIABLE: `api/workflowVariable`,
  WORKFLOW_SCENARIO: `api/workflowScenario`,
  WORKFLOW_SCENARIO_HAS_INCOMPLETE_DOCUMENTS: `api/workflowScenario/[:id]/hasIncompleteDocuments`,
  WORKFLOW_SCENARIO_WITHOUT_RELATED_DOCUMENTS_CHECKING: `api/workflowScenario/updateWithoutRelatedDocumentsChecking`,
  WORKFLOW_SCENARIOS_ALL: `api/workflowScenarios/all`,
  WORKFLOW_SCENARIOS_ALL_WITH_DELETED: `api/workflowScenarios/allWithDeleted`,
  WORKFLOW_STEP: `api/workflowStep`,
  WORKFLOW_STEPS_GET_BY_SCENARIO: `api/workflowSteps`,
  WORKFLOW_STEP_ACTIONS_GET_ALL: `api/workflowStepActions/all`,
  WORKFLOW_AGREEMENT_PROCESS_SCENARIO: `api/workflowAgreementProcessScenario`,
  WORKFLOW_AGREEMENT_PROCESS_SCENARIOS_ALL: `api/workflowAgreementProcessScenarios/all`,
  WORKFLOW_AGREEMENT_PROCESS_SCENARIOS_ALL_WITH_DELETED: `api/workflowAgreementProcessScenarios/allWithDeleted`,
  WORKFLOW_AGREEMENT_PROCESS_STEP: `api/workflowAgreementProcessStep`,
  WORKFLOW_AGREEMENT_PROCESS_STEPS: `api/workflowAgreementProcessSteps`,
  WORKFLOW_AGREEMENT_PROCESS_STEP_USERS_BULK: 'api/workflowAgreementProcessStepUsers/bytype/bulk',
  WORKFLOW_AGREEMENT_PROCESS_STEP_USERS_ACCESSIBLE_STEP_IDS: 'api/workflowAgreementProcessStepUsers/accessibleStepIds',
  WORKLFOW_HISTORY_GET_EXPORT: 'api/workflowHistoryView/export',
  WORKFLOW_QUEUE_RECORD: 'api/workflowQueueRecord',
  WORKFLOW_QUEUE_RECORD_EMAIL: 'api/workflowQueueRecord/email',
  WORKFLOW_QUEUE_RECORD_RELATED: 'api/workflowQueueRecord/related',
  WORKFLOW_QUEUE_RECORD_HISTORY: 'api/workflowQueueRecord/history',
  WORKFLOW_QUEUE_RECORD_INFO: 'api/workflowQueueRecord/info',
  WORKFLOW_QUEUE_STATES_GET_ALL: 'api/workflowQueueStates/all',
  WORKFLOW_CONDITION_MEMBERS: 'api/workflowConditions/members',
  WORKFLOW_SCENARIO_CONDITION_MEMBERS: 'api/workflowScenarioConditions/members',
  WORKFLOW_SCENARIO_CONDITION_MEMBERS_DATA: 'api/workflowScenarioConditionMembers',
  FTP_TEST_CONNECTION: 'api/ftp/testConnection',
  WORKFLOW_QUEUE_RECORDS_MERGE: 'api/workflowQueueRecords/merge',
  WORKFLOW_QUEUE_RECORD_APPLY_SCENARIO: 'api/workflowQueueRecord/applyScenario',
  WORKFLOW_QUEUE_RECORD_UNMERGE: 'api/workflowQueueRecord/unmerge',
  WORKFLOW_QUEUE_RECORD_EMPTY_BIN: 'api/workflowQueueRecords/emptyBin',
  WORKFLOW_QUEUE_RECORD_MOVE_TO_BIN: 'api/workflowQueueRecord/moveToBin',
  WORKFLOW_QUEUE_RECORD_REJECT_IN_WORKFLOW: 'api/workflowQueueRecord/rejectInWorkflow',
  WORKFLOW_QUEUE_RECORD_REMOVE: 'api/workflowQueueRecord',
  WORKFLOW_QUEUE_RECORD_RETURN_TO_SENDER: 'api/workflowQueueRecord/returnToSender',
  WORKFLOW_QUEUE_RECORD_RETURN_TO_START: 'api/workflowQueueRecord/returnToStart',
  WORKFLOW_QUEUE_RECORD_TERMINATE_PROCESS: 'api/workflowQueueRecord/terminateProcess',
  WORKFLOW_QUEUE_RECORD_GET_PARENT_GROUP_ID: 'api/workflowQueueRecord/parentGroupId',
  WORKFLOW_QUEUE_RECORD_MAIL_TO: 'api/workflowQueueRecord/sendMailTo',
  WORKFLOW_STATISTICS_GET_TOTAL_PER_MONTH: 'api/workflowStatistics/getTotalPerMonth',
  WORKFLOW_STATISTICS_GET_TOTAL_PER_QUARTER: 'api/workflowStatistics/getTotalPerQuarter',
  WORKFLOW_STATISTICS_GET_TOTAL_PER_YEAR: 'api/workflowStatistics/getTotalPerYear',
  WORKFLOW_STATISTICS_GET_EXPORT: 'api/workflowStatistics/export',
  DASHBOARD_GET_TOTAL_PER_MONTH: 'api/dashboard/getTotalPerMonth',
  DASHBOARD_GET_TOTAL_PER_QUARTER: 'api/dashboard/getTotalPerQuarter',
  DASHBOARD_GET_TOTAL_PER_YEAR: 'api/dashboard/getTotalPerYear',
  DASHBOARD_GET_TOTAL_FOR_DONUT_PER_PERIOD: 'api/dashboard/getTotalForDonutPerPeriod',
  DASHBOARD_GET_HOW_MUCH_YOU_SAVED: 'api/dashboard/getHowMuchYouSaved',
  MY_ACCOUNT: `api/myAccount`,
  MY_ACCOUNT_NEW_PASSWORD: `api/myAccount/newpassword`,
  APPS: 'api/apps',
  APP: 'api/app',
  EVENT_NOTIFICATIONS: 'api/event/notifications',
  EVENT_NOTIFICATIONS_WITHOUT_PROCESSED: 'api/event/notificationsWithoutProcessed',
  BILLING: 'api/billing',
  BILLING_BALANCE_GET: `api/billing/balance`,
  BILLING_TARIFFS_GET: 'api/billing/tariffs',
  BILLING_SET_NEXT_TARIFF: 'api/billing/setNextTariff',
  BILLING_GROUPED_TARIFF_ITEMS_GET: 'api/billing/groupedTariffItems',
  BILLING_GET_EXPORT: 'api/billing/transactions/export',
  CREATE_CREDIT_PAYMENT: `api/payment/create/credit`,
  SEND_EMAIL: 'api/email',
  SETTINGS: 'api/settings',
  USER_AGENDA_SETTING_BY_CATEGORY: 'api/setting/userAgendaCategory/[:category]',
  USER_AGENDA_SETTINGS: 'api/settings/[:type]',
  USER_AGENDA_SETTINGS_BY_CATEGORY: 'api/settings/userAgendaCategory/[:category]',
  USER_AGENDA_SETTING_CREATE: 'api/setting/[:type]',
  USER_AGENDA_SETTING_UPDATE: 'api/setting',
  WORKFLOW_QUEUE_RECORD_EMAIL_HISTORY: 'api/workflowQueueRecord/mailtohistory',
  WORKFLOW_QUEUE_RECORD_GRAPH: 'api/workflowQueueRecord/graph',
  WORKFLOW_INVOICE: 'api/workflowInvoice',
  VAT_RATES: 'api/vatRates/all',
  AGENDA_SETTINGS_BY_CATEGORY: 'api/setting/category/[:category]',
  DEVICE_CHANGE_STATE: 'api/device/[:id]/state',
  SUPPORT_CASES: 'api/supportCases',
  USER_DOCUMENTS_FOR_APPROVAL: 'api/user/documentsForApproval',
  LOG_ERROR: 'api/log'
};
