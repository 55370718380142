import { METADATA_TYPES } from "../types/workflowMetadataTypes";
import { POSITION_TYPES } from "../types/positionsTypes";
import { VatRateTypeEnum } from "../types/invoiceTypes";
import { WORKFLOW_QUEUE_SOURCE_TYPES } from "../types/workflowQueueSourceTypes";
import { translateDataset } from "../../components/common/Translation";

export const CountryCodes = [
  { text: 'CZE', value: 'CZE' }
];

export const datariosSupportMail = 'support@datarios.cz';

export const getTKeyByValue = (value: any, data: { tKey: string, value: any }[], defaultTKey: string) => {
  const tKey = data.find(el => el.value === value)?.tKey;
  return tKey ?? defaultTKey;
};

export const MetadataTypeDataset = [
  { tKey: 'Workflow.MetadataType.Email', value: METADATA_TYPES.EmailAttachment },
  { tKey: 'Workflow.MetadataType.Datarios', value: METADATA_TYPES.Datarios },
  { tKey: 'Workflow.MetadataType.DocumentAttachment', value: METADATA_TYPES.DocumentAttachment },
];

export const MetadataTypeNameDataset = translateDataset(MetadataTypeDataset);

export const WorkflowQueueSourceBaseDataset = [
  { tKey: 'Workflow.MetadataType.Email', value: WORKFLOW_QUEUE_SOURCE_TYPES.Email },
  { tKey: 'Workflow.MetadataType.Datarios', value: WORKFLOW_QUEUE_SOURCE_TYPES.Datarios },
  { tKey: 'Workflow.MetadataType.DocumentAttachment', value: WORKFLOW_QUEUE_SOURCE_TYPES.Mobile },
  { tKey: 'Workflow.MetadataType.CloudDrive', value: WORKFLOW_QUEUE_SOURCE_TYPES.CloudDrive },
];

export const WorkflowQueueSourceDataset = [
  { text: '...', value: WORKFLOW_QUEUE_SOURCE_TYPES.NotSet },
  ...translateDataset(WorkflowQueueSourceBaseDataset),
];

export const positionDropDownData = [
  { tKey: 'Before', value: POSITION_TYPES.Before },
  { tKey: 'After', value: POSITION_TYPES.After },
];

export const vatRateTypeData = [
  { tKey: 'VatRateTypes.Basic', value: VatRateTypeEnum.Basic },
  { tKey: 'VatRateTypes.ReduceRate1', value: VatRateTypeEnum.ReduceRate1 },
  { tKey: 'VatRateTypes.ReduceRate2', value: VatRateTypeEnum.ReduceRate2 },
  { tKey: 'VatRateTypes.Zero', value: VatRateTypeEnum.Zero },
  { tKey: 'VatRateTypes.ReduceRate', value: VatRateTypeEnum.ReduceRate },
];

export const vatRateTypeNameData = [
  { tKey: 'VatRateTypeNames.Basic', value: VatRateTypeEnum.Basic },
  { tKey: 'VatRateTypeNames.ReduceRate1', value: VatRateTypeEnum.ReduceRate1 },
  { tKey: 'VatRateTypeNames.ReduceRate2', value: VatRateTypeEnum.ReduceRate2 },
  { tKey: 'VatRateTypeNames.Zero', value: VatRateTypeEnum.Zero },
  { tKey: 'VatRateTypeNames.ReduceRate', value: VatRateTypeEnum.ReduceRate },
];

export const vatRateData = [
  { type: VatRateTypeEnum.Basic, value: 21 },
  { type: VatRateTypeEnum.ReduceRate1, value: 15 },
  { type: VatRateTypeEnum.ReduceRate2, value: 10 },
  { type: VatRateTypeEnum.Zero, value: 0 },
  { type: VatRateTypeEnum.ReduceRate, value: 12 },
];

export enum ConditionLogicalOperator {
  And = 1,
  Or = 2
}

export const ConditionSelectorData = [
  { tLabelKey: 'And', value: ConditionLogicalOperator.And },
  { tLabelKey: 'Or', value: ConditionLogicalOperator.Or },
];

export enum ConditionOperator {
  is = 1,
  isnot = 2,
  contains = 3,
  gt = 4,
  gte = 5,
  lt = 6,
  lte = 7
}

export const IsConditionOperatorData = { tKey: 'Is', value: ConditionOperator.is };
export const IsNotConditionOperatorData = { tKey: 'IsNot', value: ConditionOperator.isnot };

export const ConditionDropDownListData = [
  IsConditionOperatorData,
  IsNotConditionOperatorData,
];

export const ConditionDropDownTextData = [
  ...ConditionDropDownListData,
  { tKey: 'Contains', value: ConditionOperator.contains }
];

export const ConditionNumericOperatorsData = [
  { tKey: 'Is', value: ConditionOperator.is },
  { tKey: 'Operator.gt', value: ConditionOperator.gt },
  { tKey: 'Operator.gte', value: ConditionOperator.gte },
  { tKey: 'Operator.lt', value: ConditionOperator.lt },
  { tKey: 'Operator.lte', value: ConditionOperator.lte },
];

export const ConditionGroupOperatorMap = new Map<ConditionLogicalOperator, string>([
  [ConditionLogicalOperator.And, '&'],
  [ConditionLogicalOperator.Or, '|'],
]);

export const ConditionOperatorMap = new Map<ConditionOperator, string>([
  [ConditionOperator.is, '='],
  [ConditionOperator.isnot, '≠'],
  [ConditionOperator.contains, '∈'],
  [ConditionOperator.gt, '>'],
  [ConditionOperator.gte, '≥'],
  [ConditionOperator.lt, '<'],
  [ConditionOperator.lte, '≤'],
]);
